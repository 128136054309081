import React from 'react';
import KeywordFinder from './Components/KeywordFinder/KeywordFinder';
import './App.css';

function App() {
  return (
    <div className="App">
      <KeywordFinder />
    </div>
  );
}

export default App;
