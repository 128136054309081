import React, { useState } from 'react';
import './KeywordFinder.css';

const KeywordFinder = () => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalChars, setTotalChars] = useState(0);

  const fetchKeywords = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `https://keyword-research-for-youtube.p.rapidapi.com/yttags.php?keyword=${encodeURIComponent(searchTerm)}`,
        {
          method: 'GET',
          headers: {
            'x-rapidapi-host': 'keyword-research-for-youtube.p.rapidapi.com',
            'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          },
        }
      );

      const data = await response.json();
      if (data && data.related_keywords) {
        // Sort keywords by relevance: highest monthly search, highest overall score, lowest difficulty
        const sortedKeywords = data.related_keywords.sort((a, b) => {
          return (
            b.monthlysearch - a.monthlysearch ||
            b.overallscore - a.overallscore ||
            a.competition_score - b.competition_score
          );
        });

        // Filter keywords to prioritize green and yellow tags and ensure total character count does not exceed 400 characters
        let filteredKeywords = [];
        let charCount = 0;

        const greenKeywords = sortedKeywords.filter(
          (keyword) => keyword.overallscore > 60 && keyword.difficulty.toLowerCase() === 'low'
        );

        const yellowKeywords = sortedKeywords.filter(
          (keyword) => keyword.overallscore > 55 && keyword.difficulty.toLowerCase() === 'medium'
        );

        const otherKeywords = sortedKeywords.filter(
          (keyword) => !greenKeywords.includes(keyword) && !yellowKeywords.includes(keyword)
        );

        const combinedKeywords = [...greenKeywords, ...yellowKeywords, ...otherKeywords];

        for (let keyword of combinedKeywords) {
          if (charCount + keyword.keyword.length <= 400) {
            filteredKeywords.push(keyword);
            charCount += keyword.keyword.length + 2; // Include ", "
          } else {
            break;
          }
        }

        setKeywords(filteredKeywords);
        setTotalChars(charCount);
      }
    } catch (error) {
      console.error('Failed to fetch keywords:', error);
    } finally {
      setLoading(false);
    }
  };

  const getTagClassName = (keyword) => {
    if (keyword.overallscore > 60 && keyword.difficulty.toLowerCase() === 'low') {
      return 'tag tag-green';
    } else if (keyword.overallscore > 55 && keyword.difficulty.toLowerCase() === 'medium') {
      return 'tag tag-yellow';
    } else {
      return 'tag';
    }
  };

  const copyTags = () => {
    const tags = keywords.map((keyword) => keyword.keyword).join(', ');
    navigator.clipboard.writeText(tags).then(() => {
      alert('Tags copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy tags:', err);
    });
  };

  return (
    <div className="keyword-finder">
      <div className="header">
        Typebeat Keyword <span>Finder</span>
      </div>
      <input
        type="text"
        className="input-field"
        placeholder="Enter keyword"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="generate-btn" onClick={fetchKeywords}>
        Find Keywords
      </div>
      {loading && <div className="loading-text">Loading...</div>}
      {keywords.length > 0 && (
        <div className="tags-container">
          {keywords.map((keyword, index) => (
            <div key={index} className={getTagClassName(keyword)}>
              {keyword.keyword}
            </div>
          ))}
        </div>
      )}
      {keywords.length > 0 && (
        <div className="footer">
          <button className="copy-btn" onClick={copyTags}>
            Copy Tags
          </button>
          <div className="char-counter">
            {totalChars}/400 characters
          </div>
          <div className="explanation">
            <div className="green-text">Green: Easier to rank</div>
            <div className="yellow-text">Yellow: Medium difficulty to rank</div>
            <div>No color: A bit harder to rank</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KeywordFinder;
